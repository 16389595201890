

























































































































































import Vue from 'vue'
import DashboardCard from '@/components/DashboardCard.vue'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import api from '../../../../api'
export default Vue.extend({
  components: { DashboardCard },
  props: {
    accountKey: {
      type: String,
      required: true
    },
    accountData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      columns: [
        {
          label: 'Référence',
          field: 'reference',
          searchable: true
        },
        {
          label: 'Libellé',
          field: 'reason',
          searchable: true
        },
        {
          label: 'Crédit (FCFA)',
          field: 'amount',
          currency: true
        },
        {
          label: 'Débit (FCFA)',
          field: 'amount',
          currency: true
        },
        {
          label: 'Date transaction',
          field: 'createdAt',
          date: true
        },
        {
          label: 'Solde',
          field: 'solde',
          currency: true
        },
        {
          label: 'Référence FNM',
          field: 'externalReference'
        }
      ]
      /* accountData: {
        accountNumber: '',
        data: [],
        finalSold: 0,
        openSold: 0,
        totalCredit: 0,
        totalDebit: 0
      } */
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct'])
  },

  methods: {
    formatDate(date: any) {
      date = new Date(date)
      console.log({ date })

      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATE_SHORT)
    },
    proceed() {
      this.$router.push({
        name: 'finances.refunds.consignations.chargeReimbFile',
        params: {
          dispatchingId: this.$route.params.dispatchingId
        }
      })
    },
    download(this: any, format: string): void {
      this.errorMessage = ''
      this.isLoading = true
      let docName = ''
      if (this.accountData.from && this.accountData.to)
        docName = `rapport_${this.accountData.name}_du_${this.formatDate(
          this.accountData.from
        )}_au_${this.format(this.accountData.to)}`
      else docName = `rapport_${this.accountData.name}`
      if (format === 'excel') {
        api
          .download(
            `accounts/rcb/${this.accountKey}`,
            {
              format,
              from: this.accountData.from,
              to: this.accountData.to
            },
            docName
          )
          .then(response => {
            this.isLoading = false
            if (response.status != 201 && response.status != 200)
              this.errorMessage =
                'Un problème est survenu lors du téléchargement du fichier.Veuillez réessayer'
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      } else {
        api
          .get(`accounts/rcb/${this.accountKey}`, {
            params: {
              format,
              from: this.accountData.from,
              to: this.accountData.to
            }
          })
          .then(response => {
            this.isLoading = false
            if (response.status != 201 && response.status != 200)
              this.errorMessage =
                'Un problème est survenu lors du téléchargement du fichier.Veuillez réessayer'

            const a = document.createElement('a')
            a.href = response.data
            a.target = '_blank'
            a.click()
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    }
  },
  async created(this: any) {
    console.log(this.$route.params)
  }
})
